import { Component, ViewChild, EventEmitter, Output, Input, AfterViewInit } from '@angular/core';
import { ExcelService } from '../../../views/app/@core/Services/export.service';

@Component({
  selector: 'app-list-page-header',
  templateUrl: './list-page-header.component.html'
})
export class ListPageHeaderComponent implements AfterViewInit {
  @Input() isheading = true;
  displayOptionsCollapsed = false;
  @Input() isHeader: boolean = true;
  @Input() showDisplayOptions: boolean = true;
  @Input() showBtnAdd = true;
  @Input() showBtnDropDown = true;
  @Input() showOrderBy = true;
  @Input() showSearch = true;
  @Input() showItemsPerPage = true;
  @Input() showDisplayMode = true;
  @Input() displayMode = 'list';
  @Input() selectAllState = '';
  @Input() itemsPerPage = 10;
  @Input() itemOptionsPerPage = [5, 10, 20];
  @Input() itemOrder = {};
  @Input() itemOptionsOrders = [];
  @Input() CustomButton = [];
  @Input() btnSearch: boolean = true;
  @Input() serachAccessories: boolean = false
  @Input() buttonState: string = '';
  @Input() buttonDisabled: boolean = false;
  @Input() isToolbar: boolean = false;
  @Input() hideNew: boolean = false;
  @Input() hideSave: boolean = false;
  @Input() hideDelete: boolean = true;
  @Input() hidePrint: boolean = false;
  @Input() disabledNew: boolean = false;
  @Input() disabledSave: boolean = false;
  @Input() disabledDelete: boolean = false;
  @Input() disabledPrint: boolean = false;
  @Input() coilumns: any[] = [];
  @Input() rows: any[] = [];

  @Output() changeDisplayMode: EventEmitter<string> = new EventEmitter<string>();
  @Output() addNewItem: EventEmitter<any> = new EventEmitter();
  @Output() selectAllChange: EventEmitter<any> = new EventEmitter();
  @Output() searchByButton: EventEmitter<any> = new EventEmitter();
  @Output() searchKeyUp: EventEmitter<any> = new EventEmitter();
  @Output() itemsPerPageChange: EventEmitter<any> = new EventEmitter();
  @Output() changeOrderBy: EventEmitter<any> = new EventEmitter();

  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onPrint: EventEmitter<any> = new EventEmitter();
  @Output() onCustomButton: EventEmitter<any> = new EventEmitter();
  @Output() ExporttoExcel: EventEmitter<any> = new EventEmitter();

  @ViewChild('search') search: any;
  constructor(private excel: ExcelService) { }



  onSelectDisplayMode(mode: string): void {
    this.changeDisplayMode.emit(mode);
  }
  onAddNewItem(): void {
    this.addNewItem.emit(null);
  }
  selectAll(event): void {
    this.selectAllChange.emit(event);
  }
  onChangeItemsPerPage(item): void {
    this.itemsPerPageChange.emit(item);
  }

  onChangeOrderBy(item): void {

    this.itemOrder = item;

    this.changeOrderBy.emit(item);
  }
  seachval: any;
  onSearchKeyUp($event): void {
    let obj = {
      event: $event,
      item: this.itemOrder
    }
    this.seachval = $event;
    this.searchKeyUp.emit(obj);

  }

  ngAfterViewInit() {

    this.cleanValue();
  }

  searchButtton() {
    var val: any = document.getElementById('searchBy');

    let obj = {
      value: val.value ? val.value : this.seachval ? this.seachval.target.value : '',
      item: this.itemOrder
    }

    this.searchByButton.emit(obj);
  }
  cleanValue() {

    var val: any = document.getElementById('searchBy');
    if (val)
      val.value = "";
  }

  isNew() { this.onNew.emit(); }
  isSave() { this.onSave.emit(); }
  isDelete() { this.onDelete.emit(); }
  isPrint() { this.onPrint.emit(); }
  customButton(item) { this.onCustomButton.emit(item) }

  async exportExcel() {
    this.excel.exportListingTableToCSV("export")
    // this.ExporttoExcel.emit();
  }

  async exporttoPDF(type) {
    
    if (this.rows)
      this.excel.exportListingTableToCSVWithPagination("export", type, this.rows, this.coilumns);
  }
}
