import { Component, OnInit, OnDestroy, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.loginURL;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  modalRef: any;
  modalRefMessage: any;
  msg: string;
  dpImage: string = "/assets/img/profiles/user.png";

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private http: HttpClient,
    private langService: LangService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    let dp = localStorage.getItem('DPImage');
    if(dp !== "null"){
      this.dpImage = dp;
    }else{
      this.dpImage = "/assets/img/profiles/user.png";
    }
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    if (await this.authService.getUser()) {
      this.displayName = await this.authService.getUser().then((user) => {
        return user.displayName;
      });
    }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.router.navigate([this.adminRoot]);
    // this.authService.signOut().subscribe(() => {
    // });
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  listOpen() {
    localStorage.setItem('screenName', 'TO-Do List')
    this.router.navigate(['APP/application/todo'])
  }

  chatOpen() {
    this.router.navigate(['APP/application/chat'])
  }

  async openTicket() {
    localStorage.setItem('screenName', 'Ticket')
    const url = this.router.serializeUrl(this.router.createUrlTree(['APP/application/ticket']));
    window.open(url, '_blank');
  }

  openModalAdd(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  @ViewChild('modalmessage') modalmessage: TemplateRef<any>

  openModalMessage(template: TemplateRef<any>): void {
    this.modalRefMessage = this.modalService.show(template);
  }

  OK() {
    this.modalRefMessage.hide();
  }

  caption: any;

  async Submit() {
    
    let password: any = document.getElementById('passwordField');
    let repassword: any = document.getElementById('repasswordField');
    let oldpassword: any = document.getElementById('oldpasswordField');

    if (password.value === "" && repassword.value === "") {

      this.caption = 'Warning';
      this.msg = 'Password and Re-Type Password is required!'
      this.openModalMessage(this.modalmessage);
    }
    else if (password.value === "") {

      this.caption = 'Warning';
      this.msg = 'Password is required!'
      this.openModalMessage(this.modalmessage);
    } else if (repassword.value === "") {

      this.caption = 'Warning';
      this.msg = 'Re-Type Password is required!'
      this.openModalMessage(this.modalmessage);
    }
    else if (oldpassword.value === "") {

      this.caption = 'Warning';
      this.msg = 'Previous Password is required!'
      this.openModalMessage(this.modalmessage);
    }
    else if (password.value !== repassword.value) {

      this.caption = 'Validator';
      this.msg = 'Password does not match!'
      this.openModalMessage(this.modalmessage);
    }
    else {
      
      let body = {
        userID: Number(localStorage.getItem('UserID')),
        password: password.value,
        lastPassword: oldpassword.value
      }
      
      let baseUrl = environment.apiUrl + 'Users/ChangePassword';
      
      await this.http.post(baseUrl, body).toPromise().then((user: any) => {
        
        if (user.message) {

          this.caption = user.message === "Password successfully changed" ? 'Success' : 'Error';
          this.msg = user.message
          this.openModalMessage(this.modalmessage);
          if (user.message === "Password successfully changed")
            this.modalRef.hide();
        } else {

          this.modalRef.hide();
        }
      }).catch((error) => {

        this.modalRef.hide();
      });
    }
  }

  show: boolean = false;
  showold: boolean = false;
  showre: boolean = false;

  textPassword() {
    this.show = !this.show;
  }
  textRePassword() {
    this.showre = !this.showre;
  }
  textoldPassword() {
    this.showold = !this.showold;
  }
}
