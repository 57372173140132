import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ElementRef, EventEmitter, forwardRef, Input, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseService } from '../../Services/base.service';
import { Broadcast } from '../../Services/broadcast.service';
import { ServiceLocator } from '../../Services/locator.service';
import { ControlBase } from '../config';
import { LookupComponent, LookupResult } from '../lookup/lookup.component';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'biz-inputLister',
    templateUrl: 'biz-input-lister.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BizInputListerComponent),
            multi: true,
        },
        {
            // Is an InjectionToken required by this class to be able to be used as an Validator
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BizInputListerComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: BizInputListerComponent,
        },
    ],
})
export class BizInputListerComponent extends ControlBase
    implements ControlValueAccessor, AfterViewInit, Validator {
    private baseService: any;
    protected lookupSubscription: Subscription;
    displayVal: string = '';
    @Input() defaultValues: any[] = [];
    @Input() isButton: boolean = false;
    @Input() isOpen: boolean = true;
    constructor(private element: ElementRef,
        private resolver: ComponentFactoryResolver,
        private notifications: NotificationsService,
        private modalService: BsModalService,
        private broadcast: Broadcast,
        private cd: ChangeDetectorRef) {
        super()
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output() initialized: EventEmitter<BizInputListerComponent> = new EventEmitter<BizInputListerComponent>();
    @Output() BeforeOpen: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("inputlookup", { read: ViewContainerRef }) container: any;

    discloseValidatorChange: (() => void) | undefined;
    @Input()
    design: number = 1;

    @Input() minlength: number;

    patternOfElement: any;

    @Input() maxlength: number;
    // @Input() isDisabled: boolean = true;

    @Input()
    disabled: boolean = false;

    @Input()
    caption: string = "";

    @Input() inputType: string = "text"

    @Input()
    type: string = "text";

    @Input()
    rowIndex: any;

    @Input()
    apiController: string = "";

    @Input()
    apiRoute: string = "";

    @Input()
    apiModuleName: string = "";

    @Input()
    params: any[] = [];

    @Input()
    fieldName: string = "";

    @Input()
    valueMember: string = "";

    @Input()
    displayMember: string = "";

    @Input()
    lookupType: any = 'Accessory';

    _isDisabled: boolean = true;
    @Input()
    get isDisabled(): any {

        return this._isDisabled;
    }
    set isDisabled(v: any) {
        if (v === false) {
            this._isDisabled = v
        } else {
            this._isDisabled = true
        }
    }
    _width: any;
    @Input()
    get width(): any {

        return this._width;
    }
    set width(v: any) {

        if (v) {

            this._width = v - 60 + 'px'
        }
    }

    @Input() isdependent: any;

    @Input() row: any;

    @Input()
    required: boolean = false;
    innerValue: any;

    controlEl: any
    errMsg: any;
    dirtytext: boolean = false;
    isInvalid: boolean = false;

    @Input()
    get Type(): string {
        return this.type;
    }

    _data: any[] = [];
    get data(): any[] {
        return this._data;
    }

    @Input()
    set data(value: any[]) {
        this._data = value;
    }

    async Modelchange() {

        var val = Number(this.value);
        if (!isNaN(val)) this.value = val;
        //this.value = Number(this.value);
    }

    @Input()
    config: any[] = [];

    set Type(value) {

        this.type = value;
        // Binding a pattern according to its type
        if (this.type === "url") {

            this.patternOfElement = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
        }
        else if (this.type === "email") {

            this.patternOfElement = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
        }
        else if (this.type === "ipAddress") {

            this.patternOfElement = "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$";
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.value && this.type === 'date') {
                let currentDate = new Date().toISOString().substring(0, 10);
                this.value = currentDate;
            }

        }, 0);
        this.initialized.emit(this);
    }

    private onChange = (value: any) => { };
    private onTouched = () => { };

    writeValue(value: any) {
        if (value !== this.innerValue) {
            if (this.type === "date" && value) {
                value = value.toString().substring(0, 10)
            }
            this.innerValue = value;
        }
    }

    keyup(v) {
        this.value = '';
    }


    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.discloseValidatorChange = fn;
    }

    get value(): any {
        return this.innerValue;
    }

    ondelete(event: any) {
        this.value = "";
    }


    @Input()
    set value(v: any) {

        if (v !== "NaN") {
            if (v !== this.innerValue) {

                if (this.data.length > 0) {
                    if (this.data.filter(i => i[this.valueMember] === v)[0][this.valueMember]) {
                        this.displayVal = '';
                    }
                    else {
                        this.displayVal = v;
                    }

                }
                else {

                    this.displayVal = v;
                }
                this.innerValue = v;
                // this.displayVal = '';
                this.onChange(v);

                if (this.data.length > 0) {
                    // this.initialized.emit(this);
                }
            }
        } else {

            if (this.data.length === 0)
                this.displayVal = v;
        }
        // this.cd.markForCheck()

    }

    @ViewChild("input") TextBox?: ElementRef;

    validate(control: AbstractControl): ValidationErrors | null { // function validates control

        this.dirtytext = false
        this.controlEl = control

        // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && control.errors && control.errors.required) {
            this.errMsg = "error"

            this.isInvalid = true
            return { invalid: true }
        }
        else {

            this.errMsg = null
            this.isInvalid = false
        }
        return null;
    }

    onBlur() {

        var requiredField
        // check whether the control is required or not
        if (this.controlEl && this.controlEl.errors && this.controlEl.errors.required) {

            requiredField = this.controlEl.errors.required
        }
        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && requiredField) {
            this.errMsg = "error"

            this.dirtytext = true
        }
        else {

            this.errMsg = null
        }
        // Validate according to Type (email,cnic,mobile,ipAddress,url)
        if (this.type === "email") {

            if (!this.innerValue.match(this.patternOfElement)) {

                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        else if (this.type === "url") {
            if (!this.innerValue.match(this.patternOfElement)) {
                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        else if (this.type === "ipAddress") {
            if (!this.innerValue.match(this.patternOfElement)) {
                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        // else {
        //   this.errMsg = null
        // }
        this.onTouched();
    }

    componentRef: any;
    async openlister() {

        let val: any;
        if (this.params) {
            for (let index = 0; index < this.params.length; index++) {
                const el = this.params[index];
                if (el.value !== "isListerdependent") {
                    val = val ? val + '&' + el.name + '=' + el.value : el.name + '=' + el.value
                } else {
                    val = val ? val + '&' + el.name + '=' + this.row[this.isdependent] : el.name + '=' + this.row[this.isdependent]
                }

            }
        }

        if (this.row[this.isdependent]) {
            await this.baseService.get(this.apiController, this.apiRoute, val, (this.apiModuleName ? this.apiModuleName : undefined)).then(o => {
                this.data = o;
            })
        }

        this.container.clear();

        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
            LookupComponent
        );

        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.columns = this.config;
        this.componentRef.instance.rows = this.data;
        this.componentRef.instance.type = 4;
        this.componentRef.instance.caption = this.caption ? this.caption : '';

        this.componentRef.instance.onClick.subscribe((val: any) => {

            if (val && val.data) {
                this.displayVal = '';
                this.data = val.data
            }
            if (val && val.row) {

                this.displayVal = '';
                this.value = val.row.row[this.valueMember];
            }
        });

        this.componentRef.instance.open();
    }

    public triggerChanged() {
        if (this.lookupSubscription) this.lookupSubscription.unsubscribe();
        let event: any = new CustomEvent("change", { bubbles: true });
        // As Renderer is not working with Angular 10 so I use this method so it will invoke when lister select event fire

        event.value = this.value

            (this.element.nativeElement as any)["dispatchEvent"].apply(
                this.element.nativeElement,
                [event]
            );
    }

    AcccomponentRef: any;
    async openAccLister() {

        this.BeforeOpen.emit(this);

        if (this.isOpen) {

            var factory: ComponentFactory<any>;
            this.container.clear();
            this.lookupType = this.lookupType ? this.lookupType : 'Accessory';
            if (this.lookupType) {

                if (this.lookupType === 'Leather') {

                    // factory = this.resolver.resolveComponentFactory(
                    //     LeatherLookupComponent
                    // );
                } 

                this.AcccomponentRef = this.container.createComponent(factory);
                this.AcccomponentRef.instance.columns = this.config;
                this.AcccomponentRef.instance.rows = [];
                this.AcccomponentRef.instance.row = this.row;
                this.AcccomponentRef.instance.type = 4;
                this.AcccomponentRef.instance.caption = this.caption ? this.caption : '';
                this.AcccomponentRef.instance.apiController = this.apiController;
                this.AcccomponentRef.instance.apiRoute = this.apiRoute;
                this.AcccomponentRef.instance.apiModuleName = this.apiModuleName;
                this.AcccomponentRef.instance.params = this.params;
                this.AcccomponentRef.instance.isdependent = this.isdependent;


                this.AcccomponentRef.instance.defaultValues = this.defaultValues;

                this.AcccomponentRef.instance.rowIndex = this.rowIndex;
                this.AcccomponentRef.instance.onClick.subscribe((val: any) => {

                    if (val && val.row && val.row.row) {
                        // this.data = [];

                        this.displayVal = '';
                        if (this.data && this.data.length > 0)
                            this.data.push(val.row.row)
                        else {
                            this.data = [];
                            this.data.push(val.row.row)
                        }

                    }

                    if (val && val.row) {

                        this.value = val.row.row[this.valueMember];
                    }
                    this.initialized.emit(this)
                });

                this.AcccomponentRef.instance.open();
            }
        }
    }

    onEnter(v, k) {

        if (v && k && k === 13) {

            let val: any = Number(v);

            this.innerValue = val;
            this.initialized.emit(this);
        }
    }

    changeValue(v) {
        if (v) {
            let val: any = Number(v);

            this.innerValue = val;

            this.initialized.emit(this);
        }
    }

    accessoryInformation = {
        fkStoreTypeID: '',
        fkHeadID: '',
        // fkHeadExtID: new FormControl(0),
        fkHeadExtName: '',
        fkAccBrandID: '',
        fkOwns: '',
        fkWidth: '',
        fkAccColorID: '',
        fkAccBrandIDNew: '',
        fkAccSizeID: '',
        userID: '',
        unit: '',
    }

    @ViewChild('accessoryModal') accessoryModal: TemplateRef<any>;
    accessoryModalRef: any;

    async selectAccessory() {
        let bool = false;
        this.accessoryInformation.userID = localStorage.getItem('UserID');

        Object.keys(this.accessoryInformation).forEach(c => {

            if (!this.accessoryInformation[c] && bool === false) {

                bool = true;
            }
        })

        if (!bool) {

            await this.baseService.post('SalesModelsAccessories', 'saveAccessory', this.accessoryInformation, 'apiURL').then(o => {

                if (o && o.id > 0) {
                    this.data = [];

                    let obj = {
                        id: o.id,
                        name: o.name
                    }
                    this.data.push(obj);
                    this.value = o.id;
                    this.onEnter(o.id, 13)
                    this.accessoryModalRef.hide();
                } else {

                    this.notifications.create('Information', "No Record Found.",
                        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            })
        } else {
            this.notifications.create('Error', "Fill Inforamtion !!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
    }
    store: any[] = [];
    head: any[] = [];
    AccessoriesHead: any[] = [];
    brandParams: any[] = [];
    customerParams: any[] = [];
    async getStoreData(listtype) {

        let val = 'ListType=' + listtype;
        await this.baseService.get('SalesAccRequisition', 'getStoreList', val, 'Accessories').then(o => {
            if (o && o.length && o.length > 0) {
                this.store = o;
            }
        })
    }

    async openModal() {
        this.accessoryModalRef = this.modalService.show(this.accessoryModal, {
            animated: true,
            backdrop: 'static',
            class: 'modal-md',
        });
        this.brandParams = [{ name: 'mfiled', value: 'name' }, { name: 'fvalue', value: 'value' }]
        this.customerParams = [{ name: 'mfiled', value: 'cname' }, { name: 'fvalue', value: 'value' }, { name: 'SegmentID', value: localStorage.getItem('LocationID') }, { name: 'UserID', value: localStorage.getItem('UserID') }]

        Object.keys(this.accessoryInformation).forEach(controlName => {
            this.accessoryInformation[controlName] = undefined;
        })
        await this.getStoreData(0);
    }

    async FillAccHead(listtype, categoryid) {
        this.head = [];
        let val = 'ListType=' + listtype + '&CategoryID=' + categoryid;
        await this.baseService.get('SalesAccRequisition', 'FillAccHead', val, 'Accessories').then(o => {
            if (o && o.length && o.length > 0) {
                this.head = o;
            } else {
            }
        })
    }

    async getHeadExt(tType, FKHeadID) {
        this.AccessoriesHead = [];
        let val = 'tType=' + tType + '&FKHeadID=' + FKHeadID;
        await this.baseService.get('SalesModelsAccessories', 'getHeadExt', val, 'apiURL').then(o => {
            if (o && o.length && o.length > 0) {
                this.AccessoriesHead = o;
            } else {
            }
        })
    }

    async changeHead(id) {

        if (id) {
            await this.getHeadExt(0, id);
        }
    }
    public isChange: boolean = false;
    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.value && changes.value.previousValue !== undefined) {
            this.isChange = true;
            this.initialized.emit(this);
        }else{
            this.isChange = false;
        }
    }

}
