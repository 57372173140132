import { NgModule } from "@angular/core";
import { BizInputModule } from "./biz-input/biz-input.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BizDropdownModule } from './biz-dropdown/biz-dropdown.module';
import { BizDatatableModule } from './biz-datatable/biz-datatable.module';
import { LookupModule } from './lookup/lookup.module';
import { BsModalService } from "ngx-bootstrap/modal";
import { BizEditorModule } from './biz-editor/biz-editor.module';
import { BizInputListerModule } from './biz-input-lister/biz-input-lister.module';
import { ComponentsStateButtonModule } from '../../../../components/state-button/components.state-button.module';
import { BizSelectModule } from './biz-select/biz-select.module';

import { BizDatalistModule } from './biz-datalist/biz-datalist.module';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        BizInputModule,
        BizDropdownModule,
        BizDatatableModule,
        BizEditorModule,
        BizInputListerModule,
        BizSelectModule,
        BizDatalistModule,
        ComponentsStateButtonModule,
        
        LookupModule,
      
    ],
    declarations: [],
    exports: [
        BizInputModule,
        BizDropdownModule,
        BizDatatableModule,
        BizEditorModule,
        BizInputListerModule,
        BizSelectModule,
        BizDatalistModule,
        
        LookupModule,
       
    ],
    entryComponents: [],
    providers: [BsModalService],
})
export class ComponentModule { }
