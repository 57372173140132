// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { ReportGridViewerComponent } from './report-grid-viewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, NgxDatatableModule, AccordionModule
    ],
    declarations: [
        ReportGridViewerComponent,
    ],
    exports: [
        ReportGridViewerComponent,
    ]
})
export class ReportGridViewerModule {

}
