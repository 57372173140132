<!--component html goes here -->
<div *ngIf="design === 1" class="form-group has-error inputContainer">
    <input class="form-control" [type]="type" [required]="required" [(ngModel)]="value" [disabled]="disabled" (keyup.enter)="keyUp()"
        (blur)="onBlur()" [minlength]="minlength" [maxlength]="maxlength" pattern="{{patternOfElement}}" />
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;">
        <!-- Condition to show Tooltip -->
        <span class="tooltipss" type="button">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            <!-- <span class="tooltiptextss">
                <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
                {{errMsg}}
            </span> -->
        </span>
    </span>
    <span class="spanCaption" for="texto">{{caption}}</span>
</div>

<label *ngIf="design === 2" class="form-group has-float-label">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <input #input="ngModel" [ngModelOptions]="{standalone: true}" [type]="type" class="form-control" [placeholder]="placeholder"
            [required]="required" [(ngModel)]="value" [disabled]="disabled" (blur)="onBlur()" [minlength]="minlength"
            [maxlength]="maxlength" pattern="{{patternOfElement}}" (keyup.enter)="keyUp()"/>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 7px;">
        <!-- Condition to show Tooltip -->
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
            <!-- <span class="tooltiptextss">
                <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
                {{errMsg}}
            </span> -->
        </span>
    </span>
    <span class="spanCaption">{{caption}}</span>
</label>

<input #input="ngModel" *ngIf="design === 3" [ngModelOptions]="{standalone: true}" [type]="type" [(ngModel)]="value" [disabled]="disabled"
    style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" (blur)="onBlur()"
    [minlength]="minlength" [maxlength]="maxlength" (keyup.enter)="keyUp()"/>
