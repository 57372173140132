<div class="row">
  <div class="col-12">
    <app-heading *ngIf="isheading"></app-heading>
    <div *ngIf="isToolbar" class="top-right-toolbar-container">
      <!-- <button *ngIf="showBtnAdd" type="button" (click)="onAddNewItem()"
        class="top-right-button btn btn-primary btn-lg mr-1">{{
        'pages.add-new' | translate}}</button> -->
      <button *ngIf="!hideNew" type="button" [disabled]="disabledNew" data-toggle="tooltip" title="Add New" data-placement="top" (click)="isNew()"
        class="btn btn-secondary default mb-1 btn-xs pt-2"> <i class="simple-icon-plus"></i>
      </button>{{ " " }}
      <button *ngIf="!hideSave" type="button" [disabled]="disabledSave" data-toggle="tooltip" title="Save" data-placement="top" (click)="isSave()"
        class="btn btn-success default mb-1 btn-xs pt-2">
        <i class="iconsminds-save"></i> </button>{{ " " }}
      <button *ngIf="!hideDelete" type="button" data-toggle="tooltip" [disabled]="disabledDelete" title="Delete" data-placement="top" (click)="isDelete()"
        class="btn btn-danger default mb-1 btn-xs pt-2"> <i class="simple-icon-close"></i>
      </button>{{ " " }}
      <button *ngIf="!hidePrint" type="button" data-toggle="tooltip" [disabled]="disabledPrint" title="Print" data-placement="top" (click)="isPrint()"
        class="btn btn-dark default mb-1 btn-xs pt-2"> <i class="iconsminds-printer"></i>
      </button>{{ " " }}
      <button *ngFor="let item of CustomButton" type="button" data-toggle="tooltip" [title]="item.title" data-placement="top" (click)="customButton(item)"
        class="btn btn-warning default mb-1 btn-xs pt-2"> <i [class]="item.icon ? item.icon : 'simple-icon-menu'"></i>
      </button>{{ " " }}
    </div>
    <app-breadcrumb *ngIf="isheading"></app-breadcrumb>
    <!-- <div class="text-right" *ngIf="isToolbar">
      <button type="button" class="btn btn-secondary default mb-1 btn-xs"> <i class="simple-icon-plus"></i>
      </button>{{
      " " }}
      <button type="button" class="btn btn-success default mb-1 btn-xs">
        <i class="iconsminds-save"></i> </button>{{
      " " }}
      <button type="button" class="btn btn-danger default mb-1 btn-xs"> <i class="simple-icon-close"></i>
      </button>{{ " " }}
      <button type="button" class="btn btn-dark default mb-1 btn-xs"> <i class="iconsminds-printer"></i>
      </button>
    </div> -->
    <div class="display-options-container">
      <a *ngIf="showDisplayOptions" class="btn pt-0 pl-0 d-inline-block d-md-none c-pointer" data-toggle="collapse"
        (click)="displayOptionsCollapsed = !displayOptionsCollapsed" role="button" aria-expanded="true"
        aria-controls="displayOptions">
        {{ 'pages.display-options' | translate}} {{' '}}
        <i class="simple-icon-arrow-down align-middle"></i>
      </a>
      <div *ngIf="showDisplayOptions" id="displayOptions" [collapse]="displayOptionsCollapsed"
        class="collapse dont-collapse-sm">
        <span *ngIf="showDisplayMode" class="mr-3 mb-2 d-inline-block float-md-left">
          <a href="javascript:;" [ngClass]="{'mr-2 view-icon':true , 'active' : displayMode==='list'}"
            (click)="onSelectDisplayMode('list')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <path class="view-icon-svg" d="M17.5,3H.5a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Z" />
              <path class="view-icon-svg" d="M17.5,10H.5a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Z" />
              <path class="view-icon-svg" d="M17.5,17H.5a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Z" />
            </svg>
          </a>
          <a href="javascript:;" [ngClass]="{'mr-2 view-icon':true , 'active' : displayMode==='thumb'}"
            (click)="onSelectDisplayMode('thumb')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <path class="view-icon-svg" d="M17.5,3H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z" />
              <path class="view-icon-svg"
                d="M3,2V3H1V2H3m.12-1H.88A.87.87,0,0,0,0,1.88V3.12A.87.87,0,0,0,.88,4H3.12A.87.87,0,0,0,4,3.12V1.88A.87.87,0,0,0,3.12,1Z" />
              <path class="view-icon-svg"
                d="M3,9v1H1V9H3m.12-1H.88A.87.87,0,0,0,0,8.88v1.24A.87.87,0,0,0,.88,11H3.12A.87.87,0,0,0,4,10.12V8.88A.87.87,0,0,0,3.12,8Z" />
              <path class="view-icon-svg"
                d="M3,16v1H1V16H3m.12-1H.88a.87.87,0,0,0-.88.88v1.24A.87.87,0,0,0,.88,18H3.12A.87.87,0,0,0,4,17.12V15.88A.87.87,0,0,0,3.12,15Z" />
              <path class="view-icon-svg" d="M17.5,10H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z" />
              <path class="view-icon-svg" d="M17.5,17H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z" />
            </svg>
          </a>
          <a href="javascript:;" [ngClass]="{'mr-2 view-icon':true , 'active' : displayMode==='image'}"
            (click)="onSelectDisplayMode('image')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <path class="view-icon-svg"
                d="M7,2V8H1V2H7m.12-1H.88A.87.87,0,0,0,0,1.88V8.12A.87.87,0,0,0,.88,9H7.12A.87.87,0,0,0,8,8.12V1.88A.87.87,0,0,0,7.12,1Z" />
              <path class="view-icon-svg"
                d="M17,2V8H11V2h6m.12-1H10.88a.87.87,0,0,0-.88.88V8.12a.87.87,0,0,0,.88.88h6.24A.87.87,0,0,0,18,8.12V1.88A.87.87,0,0,0,17.12,1Z" />
              <path class="view-icon-svg"
                d="M7,12v6H1V12H7m.12-1H.88a.87.87,0,0,0-.88.88v6.24A.87.87,0,0,0,.88,19H7.12A.87.87,0,0,0,8,18.12V11.88A.87.87,0,0,0,7.12,11Z" />
              <path class="view-icon-svg"
                d="M17,12v6H11V12h6m.12-1H10.88a.87.87,0,0,0-.88.88v6.24a.87.87,0,0,0,.88.88h6.24a.87.87,0,0,0,.88-.88V11.88a.87.87,0,0,0-.88-.88Z" />
            </svg>
          </a>
        </span>
        <div class="d-block d-md-inline-block position-relative" *ngIf="showOrderBy">
          <div class="float-md-left mr-1 mb-1" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-dark btn-xs dropdown-toggle"
              aria-controls="dropdown-basic" (click)="cleanValue()">
              Search By : {{itemOrder.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let item of itemOptionsOrders"><a
                  [ngClass]="item.value === itemOrder.value ? 'dropdown-item active' : 'dropdown-item'"
                  (click)="onChangeOrderBy(item)">{{item.label}}</a></li>
            </ul>
          </div>
        </div>

        <div class="d-inline-block" style="position: absolute;" *ngIf="showSearch">
          <div class="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
            <input id="searchBy" class="form-control" (keyup)="onSearchKeyUp($event)" (keydown.enter)="searchButtton()" placeholder="Search"
              style="height: 25px;">
            <!-- (keyup)="onSearchKeyUp($event)" -->
          </div>
          <button *ngIf="btnSearch" type="button" class="btn btn-primary btn-xs" style="color: #fff;"
            (click)="searchButtton()">Search</button>
          <button *ngIf="serachAccessories" (click)="searchButtton()"
            [class]="buttonState + ' ' + 'btn btn-primary btn-xs btn-shadow' + ' btn-multiple-state'"
            [popover]="message" triggers="">
            <div class="spinner d-inline-block" style="z-index: 10;">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            {{ 'form-components.search' | translate | uppercase }}
          </button>
          <!-- <app-state-button *ngIf="serachAccessories" [btnClass]="'btn btn-primary btn-lg btn-shadow'"
            [currentState]="buttonState" [isDisabled]="buttonDisabled" (click)="searchButtton()">
            {{ 'form-components.search' | translate | uppercase }}
          </app-state-button> -->
        </div>



        <div *ngIf="showBtnDropDown" style="margin-top: -10px;" class="btn-group float-right" dropdown>
          <button id="button-alignment" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-alignment">
            Export <span class="caret"></span>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
            aria-labelledby="button-alignment">
            <li role="menuitem" (click)="exporttoPDF(2)"><a class="dropdown-item" href="javascript:;">Pdf</a></li>
            <li role="menuitem" (click)="exporttoPDF(1)" ><a class="dropdown-item" href="javascript:;">Excel</a></li>
          </ul>
        </div>
        <div class="float-right" *ngIf="showItemsPerPage">
          <span class="text-muted text-small">Per Page </span>
          <span dropdown class="d-inline-block position-relative">
            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" dropdownToggle>
              {{itemsPerPage}}
            </button>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right" *dropdownMenu>
              <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                  [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                  (click)="onChangeItemsPerPage(item)">{{item}}</a></li>
            </div>
          </span>
        </div>
        {{ " " }}
      </div>
      <div [class]="showBtnAdd === true ? 'separator mb-0 mt-2' : 'separator mb-0'"></div>
    </div>
  </div>
</div>