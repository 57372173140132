import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import * as moment from 'moment';
import { ControlBase } from '../config';

@Component({
    selector: 'biz-input',
    templateUrl: 'biz-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BizInputComponent),
            multi: true,
        },
        {
            // Is an InjectionToken required by this class to be able to be used as an Validator
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BizInputComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: BizInputComponent,
        },
    ],

})
export class BizInputComponent extends ControlBase
    implements ControlValueAccessor, AfterViewInit, Validator {

    @Output() initialized: EventEmitter<BizInputComponent> = new EventEmitter<BizInputComponent>();

    discloseValidatorChange: (() => void) | undefined;
    @Input()
    design: any = 1;

    @Input() minlength: number;

    patternOfElement: any;

    @Input() maxlength: number;

    @Input()
    disabled: boolean = false;

    @Input()
    caption: string = "";
    @Input()
    placeholder: string = "";

    @Input()
    type: string = "text";

    @Input()
    required: boolean = false;
    innerValue: any;

    controlEl: any
    errMsg: any;
    dirtytext: boolean = false;
    isInvalid: boolean = false;

    @Input()
    get Type(): string {
        return this.type;
    }

    set Type(value) {

        this.type = value;
        // Binding a pattern according to its type
        if (this.type === "url") {

            this.patternOfElement = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
        }
        else if (this.type === "email") {
            this.patternOfElement = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        }
        else if (this.type === "ipAddress") {

            this.patternOfElement = "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$";
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.value && this.type === 'date') {
                let currentDate = new Date().toISOString().substring(0, 10);
                this.value = currentDate;
            }
            if (!this.value && this.type === 'month') {
                let currentDate = moment(new Date()).format('yyyy-MM')
                this.value = currentDate;
            }
            this.initialized.emit(this)
        }, 0);
    }

    private onChange = (value: any) => { };
    private onTouched = () => { };

    writeValue(value: any) {

        if (value !== this.innerValue) {
            if (this.type === "date" && value) {
                value = value.toString().substring(0, 10)
            }
            this.innerValue = value;
        }
    }

    changeValue(v) {

    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.discloseValidatorChange = fn;
    }

    get value(): any {
        return this.innerValue;
    }


    @Input()
    set value(v: any) {

        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChange(v);
        } else if (v === undefined) {
            this.onChange(undefined);
        }

    }

    keyUp() {
        this.initialized.emit(this);
    }

    @ViewChild("input") TextBox?: ElementRef;

    validate(control: AbstractControl): ValidationErrors | null { // function validates control

        this.dirtytext = false
        this.controlEl = control

        // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && control.errors && control.errors.required) {
            this.errMsg = "error"

            this.isInvalid = true
            return { invalid: true }
        }
        else {

            this.errMsg = null
            this.isInvalid = false
        }
        
        if (this.Type === "email") {
            
            if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && control.errors && control.errors.required) {
                this.errMsg = "error"

                this.isInvalid = true
                return { invalid: true }
            }
            else {
                if (this.innerValue && !this.innerValue.match(this.patternOfElement)) {
                    this.errMsg = "error"

                    this.isInvalid = true
                    return { invalid: true }
                }
                else {
                    this.errMsg = null
                    this.isInvalid = false
                }
            }
        }
        return null;
    }

    onBlur() {

        var requiredField
        // check whether the control is required or not
        if (this.controlEl && this.controlEl.errors && this.controlEl.errors.required) {

            requiredField = this.controlEl.errors.required
        }
        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && requiredField) {
            this.errMsg = "error"

            this.dirtytext = true
        }
        else {

            this.errMsg = null
        }
        // Validate according to Type (email,cnic,mobile,ipAddress,url)
        if (this.type === "email") {

            if (!this.innerValue.match(this.patternOfElement)) {

                this.dirtytext = true
                this.errMsg = 'should be in right format'

            }
        }
        else if (this.type === "url") {
            if (!this.innerValue.match(this.patternOfElement)) {
                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        else if (this.type === "ipAddress") {
            if (!this.innerValue.match(this.patternOfElement)) {
                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        // else {
        //   this.errMsg = null
        // }
        this.onTouched();
    }

}

