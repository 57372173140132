import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { ControlBase } from '../config';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'biz-select',
    templateUrl: 'biz-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BizSelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BizSelectComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: BizSelectComponent,
        },
    ],
})
export class BizSelectComponent extends ControlBase
    implements ControlValueAccessor, Validator, AfterViewInit {
    @Output() initialized: EventEmitter<BizSelectComponent> = new EventEmitter<BizSelectComponent>();
    @ViewChild('ngSelect') ngSelect: NgSelectComponent;
    private baseService: any;
    isloading = false;
    constructor(private element: ElementRef, private resolver: ComponentFactoryResolver) {
        super();
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("leatherlookup", { read: ViewContainerRef }) container: any;

    ngAfterViewInit() {
        this.initialized.emit(this);
    }
    private onChange = (value: any) => { };
    private onTouched = () => { };

    innerValue: any;
    @Input()
    type: number = 1;

    @Input()
    design: any = 2;

    @Input()
    config: any[] = [];

    @Input()
    placeholder: string = "";
    @Input()
    valueMember: string = "";

    @Input()
    displayMember: string = "";

    @Input()
    caption: string = "";

    @Input()
    fieldName: string = "";

    @Input()
    disabled: boolean = false;
    @Input()
    isButton: boolean = false;

    @Input()
    listerColumns: any[] = [];
    @Input()
    lookupType: any = 'Leather';

    @Input() apiController: any;
    @Input() apiRoute: any;
    @Input() apiModule: any;
    @Input() searchBY: any;
    @Input() apiParameters: any[] = [];

    selectedRows: any;
    _width: any;
    @Input()
    get width(): any {

        return this._width;
    }
    set width(v: any) {

        if (v) {

            this._width = v - 45 + 'px'
        }
    }
    @Input()
    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {

        if (v && v !== this.innerValue) {

            this.innerValue = v;
            this.onChange(v);
        }
    }

    keyDownFn(e) {

    }

    async changeValue(e) {
        if (e) {
            this.change.emit(e);
        }
    }

    controlEl: any
    errMsg: any;
    dirtytext: boolean = false;
    isInvalid: boolean = false;

    _data: any[] = [];
    get data(): any[] {
        return this._data;
    }

    @Input()
    set data(value: any[]) {
        if (value && value.length > 0)
            this._data = value;
        else
            this._data = [];
    }

    validate(control: AbstractControl): ValidationErrors | null {

        this.dirtytext = false
        this.controlEl = control

        // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && control.errors && control.errors.required) {
            this.errMsg = "error"

            this.isInvalid = true
            return { invalid: true }
        }
        else {

            this.errMsg = null
            this.isInvalid = false
        }
        return null;
    }

    getErrorDescrption(caption: any) {

    }

    async keyup(e) {
        if (e && e.length === 3) {
            
            if (this.apiController && this.apiRoute && this.data.length === 0) {

                let val;
                if (this.apiParameters.length > 0) {
                    for (let index = 0; index < this.apiParameters.length; index++) {
                        const el = this.apiParameters[index];
                        if (el.value !== 'value') {
                            val = val ? val + '&' + el.name + '=' + el.value : el.name + '=' + el.value
                        } else {
                            val = val ? val + '&' + el.name + '=' + e : el.name + '=' + e
                        }

                    }
                }

                let module = this.apiModule ? this.apiModule : ''
                this.isloading = true;
                await this.baseService.get(this.apiController, this.apiRoute, val, module, false).then(o => {
                    this.isloading = false;
                    if (this.ngSelect) {
                        this.ngSelect.items = o;
                    }
                    this.data = o;
                }).catch(e => {
                    this.isloading = false;
                })
            }
        }
    }

    writeValue(value: any) {

        if (value !== this.innerValue) {

            this.innerValue = value;
        }
    }

    clear() {

        if (this.ngSelect) {
            this.ngSelect.items = [];
        }
        this.data = [];
    }

    remove() {

    }

    async Modelchange() {

        var val = Number(this.value);
        if (!isNaN(val)) this.value = val;
        //this.value = Number(this.value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {

        this.onTouched = fn;
    }

    onBlur() {
        var requiredField;

        if (
            this.controlEl &&
            this.controlEl.errors &&
            this.controlEl.errors.required
        ) {
            requiredField = this.controlEl.errors.required;
        }
        if (
            (this.innerValue === null ||
                this.innerValue === "" ||
                this.innerValue === undefined) &&
            requiredField
        ) {
            // console.log(this.innerValue.matchAll(this.inputMask))

            this.errMsg = "";
            this.dirtytext = true;
        } else {
            this.errMsg = null;
            this.dirtytext = false;
        }
        this.onTouched();
    }

    componentRef: any;
    async openLister() {

        var factory: ComponentFactory<any>;
        this.container.clear();
        this.lookupType = this.lookupType ? this.lookupType : 'Leather';
        if (this.lookupType) {
            if (this.lookupType === 'Leather') {

                // factory = this.resolver.resolveComponentFactory(
                //     LeatherLookupComponent
                // );
            } 
            

            this.componentRef = this.container.createComponent(factory);
            this.componentRef.instance.columns = this.config;
            this.componentRef.instance.rows = [];
            this.componentRef.instance.type = 4;
            this.componentRef.instance.caption = this.caption ? this.caption : '';
            this.componentRef.instance.onClick.subscribe((val: any) => {

                this.data = [];
                if (this.ngSelect) {

                    this.ngSelect.items = [];
                }
                if (val && val.row) {
                    this.data.push(val.row.row);

                    if (this.ngSelect) {

                        this.ngSelect.items.push(val.row.row);
                    }
                    this.value = val.row.row[this.valueMember];
                    this.change.emit(this.value);
                }
            });

            this.componentRef.instance.open();
        }
    }

    async ButtonClick() {
        this.onButtonClick.emit();
    }

    async enter(e, key) {
        if (e && key === 13) {
            
            if (this.apiController && this.apiRoute) {
                let val;
                if (this.apiParameters.length > 0) {
                    for (let index = 0; index < this.apiParameters.length; index++) {
                        const el = this.apiParameters[index];
                        if (el.value !== 'value') {
                            val = val ? val + '&' + el.name + '=' + el.value : el.name + '=' + el.value
                        } else {
                            val = val ? val + '&' + el.name + '=' + e : el.name + '=' + e
                        }

                    }
                }
                let module = this.apiModule ? this.apiModule : ''
                this.isloading = true;
                await this.baseService.get(this.apiController, this.apiRoute, val, module, false).then(o => {
                    this.isloading = false;
                    if (this.ngSelect) {
                        this.ngSelect.items = o;
                    }
                    this.data = o;
                }).catch(e => {
                    this.isloading = false;
                })
            }
        }
    }
}
